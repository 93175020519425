var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"patup","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticClass:"my-4",staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h1',{staticClass:"primary--text text-h1"},[_vm._v(" Join the community ")])])],1),_c('v-card',{staticClass:"mx-auto py-8",attrs:{"width":"800"}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Last Name","prepend-inner-icon":"mdi-account-box","outlined":"","error-messages":errors,"label":"First Name"},model:{value:(_vm.editedItem.firstName),callback:function ($$v) {_vm.$set(_vm.editedItem, "firstName", $$v)},expression:"editedItem.firstName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Last Name","error-messages":errors,"prepend-inner-icon":"mdi-account-outline","outlined":"","label":"Last Name"},model:{value:(_vm.editedItem.lastName),callback:function ($$v) {_vm.$set(_vm.editedItem, "lastName", $$v)},expression:"editedItem.lastName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Email","prepend-inner-icon":"mdi-at","error-messages":errors,"outlined":"","label":"Email"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"placeholder":"Phone Number","return-masked-value":"","mask":"##########","error-messages":errors,"prepend-inner-icon":"mdi-phone","outlined":"","label":"Phone Number"},model:{value:(_vm.editedItem.phone),callback:function ($$v) {_vm.$set(_vm.editedItem, "phone", $$v)},expression:"editedItem.phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-map-marker","placeholder":"Address","error-messages":errors,"outlined":"","label":"Address"},model:{value:(_vm.editedItem.address),callback:function ($$v) {_vm.$set(_vm.editedItem, "address", $$v)},expression:"editedItem.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DOB","error-messages":errors,"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.editedItem.dob),callback:function ($$v) {_vm.$set(_vm.editedItem, "dob", $$v)},expression:"editedItem.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":_vm.saveDate},model:{value:(_vm.editedItem.dob),callback:function ($$v) {_vm.$set(_vm.editedItem, "dob", $$v)},expression:"editedItem.dob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Emergency Contact Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Emergency Contact Name","prepend-inner-icon":"mdi-shield-account-outline","outlined":"","error-messages":errors,"label":"Emergency Contact Name"},model:{value:(_vm.editedItem.emergencyContactName),callback:function ($$v) {_vm.$set(_vm.editedItem, "emergencyContactName", $$v)},expression:"editedItem.emergencyContactName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Emergency Contact Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"error":_vm.hasErrorActive,"error-messages":errors,"outlined":"","label":"Emergency Contact Phone","placeholder":"Emergency Contact Phone","prepend-inner-icon":"mdi-phone-outline"},model:{value:(_vm.editedItem.emergencyContactPhone),callback:function ($$v) {_vm.$set(_vm.editedItem, "emergencyContactPhone", $$v)},expression:"editedItem.emergencyContactPhone"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onAddClient}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Save ")],1)],1)],1),_c('v-progress-linear',{attrs:{"active":_vm.fetchup,"indeterminate":_vm.fetchup,"absolute":"","bottom":"","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }