<template>
  <v-container
    id="patup"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        style="text-align: center"
        class="my-4"
      >
        <h1 class="primary--text text-h1">
          Join the community
        </h1>
      </v-col>
    </v-row>
    <v-card
      class="mx-auto py-8"
      width="800"
    >
      <v-card-text>
        <validation-observer
          ref="observer"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.firstName"
                    placeholder="Last Name"
                    prepend-inner-icon="mdi-account-box"
                    outlined
                    :error-messages="errors"
                    label="First Name"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.lastName"
                    placeholder="Last Name"
                    :error-messages="errors"
                    prepend-inner-icon="mdi-account-outline"
                    outlined
                    label="Last Name"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="editedItem.email"
                    placeholder="Email"
                    prepend-inner-icon="mdi-at"
                    :error-messages="errors"
                    outlined
                    label="Email"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone Number"
                  :rules="{
                    required: true,
                  }"
                >
                  <vue-tel-input-vuetify
                    v-model="editedItem.phone"
                    placeholder="Phone Number"
                    return-masked-value
                    mask="##########"
                    :error-messages="errors"
                    prepend-inner-icon="mdi-phone"
                    outlined
                    label="Phone Number"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.address"
                    prepend-inner-icon="mdi-map-marker"
                    placeholder="Address"
                    :error-messages="errors"
                    outlined
                    label="Address"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Date of Birth"
                      rules="required"
                    >
                      <v-text-field
                        v-model="editedItem.dob"
                        label="DOB"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="editedItem.dob"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveDate"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Emergency Contact Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.emergencyContactName"
                    placeholder="Emergency Contact Name"
                    prepend-inner-icon="mdi-shield-account-outline"
                    outlined
                    :error-messages="errors"
                    label="Emergency Contact Name"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Emergency Contact Phone"
                  rules="required"
                >
                  <vue-tel-input-vuetify
                    v-model="editedItem.emergencyContactPhone"
                    :error="hasErrorActive"
                    :error-messages="errors"
                    outlined
                    label="Emergency Contact Phone"
                    placeholder="Emergency Contact Phone"
                    prepend-inner-icon="mdi-phone-outline"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </validation-observer>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey darken-1"
          text
          @click="$router.go(-1)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="onAddClient"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear
      :active="fetchup"
      :indeterminate="fetchup"
      absolute
      bottom
      color="primary"
    />
  </v-container>
</template>
<script>
    /* eslint-disable */
    import { mapGetters } from 'vuex'
    import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Email must be valid',
    })


  const axios = require('axios')
  export default {
    name: 'ClientSignup',
    data: () => ({
        hasErrorActive: false,
      search: null,
      fetchup: false,
      menu: false,
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        phone: '',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        phone: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
    }),

    computed: {
      ...mapGetters(['getPackages']),
      formTitle () {
        return'Register New Client'
      },
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },

    created () {
      this.hideHead()
    },

    methods: {
        hideHead () {
            this.$store.commit('setBlankSlate', false)
        },
      onAddClient () {
          const self = this

            this.$refs.observer.validate().then((result) => {
            if (result) {
                this.loader = true
                const catData = this.editedItem

                axios.post(this.apiSet.clients, catData)
                    .then(function (response) {
                        self.fetchup = false
                        self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                        self.$router.go(-1)
                    })
                    .catch(error => {
                        self.loader = false
                        self.err.push(error)
                    })
            }else{
                self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
            }

            })
      },
    },
  }
</script>
<style scoped>
  #patup {
    /*background:rgba(255,255,255,0.9);*/
    background:url('https://res.cloudinary.com/fitrip/image/upload/v1619484637/elegant-seamless-pattern-black-circles-with-gold-dots-on-dark-background-texture-vector_n5pntd.jpg');
    border:4px solid rgba(255,255,255,0.6);
    border-radius: 10px;
    height: 100%;
  }
  .pulse {
    cursor: pointer;
    border-radius:50px;
    animation: pulse 1s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    }
  }
</style>
